import type { NewSearchSubmit } from "./_types";
import logEvent from './logEvent'
import type { LoggingEvent } from './logEvent'

/**
 *
 * @param props This object contains
 */

const newSearchSubmit = ({ type, category, criteria, url }: NewSearchSubmit) => {
  // UA
  // This is handled by the default pageView

  // Tag Manager
  let layerUpdate: LoggingEvent = { event: "search_new_submit" };

  if (type) layerUpdate.type = type;
  if (category) layerUpdate.category = category;
  if (criteria) layerUpdate.criteria = criteria;
  if (url) layerUpdate.url = url;

  logEvent(layerUpdate);
  // dataLayer.push(layerUpdate);
};

export default newSearchSubmit;
