import { useMemo } from "react";
import _THEME from "config/app.theme";
const RGBtoHEX = (grad) => "#" + grad.map((col) => col.toString(16)).join("");


function create_UUID(){
  var dt = new Date().getTime();
  var uuid = 'xxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

type RoundelProps = {
  width?: number;
  height?: number;
  uid?: string;
  gradToUse?: string;
};

const CogRoundelLoader = ({
  width,
  height,
  uid,
  gradToUse = "retail",
}: RoundelProps) => {
  uid = uid ? `${uid}_` : create_UUID();

  const grad = useMemo(
    () => ({
      start: '#F73261',
      end: '#F73261',
      accent: '#FFC3B5',
    }),
    [gradToUse]
  );

  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width={width ? width : undefined}
      height={height ? height : undefined}
      viewBox="0 0 65.96 65.96"
      id={'loaderSVG'}
    >
      <g id={`${uid}Isolation_Mode`}>
        <g style={{ zIndex: 1}} id={`${uid}donut`}>
          <linearGradient
            id={`${uid}SVGID_1_`}
            gradientUnits="userSpaceOnUse"
            x1=".82"
            y1="32.98"
            x2="59.437"
            y2="32.98"
          >
            <stop offset="0%" stopColor={grad.start} />
            <stop offset="100%" stopColor={grad.end} />
          </linearGradient>
          <path
            fill={`url(#${uid}SVGID_1_)`}
            d="
                    M32.98 15.73
                    C42.51 15.73
                     50.23 23.45
                     50.23 32.98
                    L65.96 32.98
                    C65.96 14.77
                     51.19 0
                     32.98 0
                    C14.77 0
                     0     14.77
                     0     32.98
                    C0     51.19
                     14.77 65.96
                     32.98 65.96
                    C51.19 65.96
                     65.96 51.19
                     65.96 32.98
                    L50.23 32.98
                    C50.23 42.53
                     42.53 50.23
                     32.98 50.23
                    C23.45 50.23
                     15.73 42.53
                     15.73 32.98
                    C15.73 23.45
                     23.45 15.73
                     32.98 15.73Z
                    "
          />
        </g>
        <g  className="loaderSVG"id={`${uid}slice`}>
          <path
            fill={grad.accent}
            d="
                    M61.34 16.00
                    L47.50 23.87
                    A17.25 17.25
                     0 0 1
                     47.50 42.09
                    L61.34 49.96
                    A32.98 32.98
                     0 0 0
                     61.34 16.00Z
                "
          />
        </g>
      </g>
    </svg>
  );
};

export default CogRoundelLoader;
