// import ReactGA from "react-ga";

import init from "./init";
import logEvent from './logEvent'

import type { PageView } from "./_types";

/**
 *
 * @param props This object contains
 */

const pageView = ({ url }: PageView) => {
  // UA
  init();
  // ReactGA.pageview(url);

  logEvent({event: 'page_view', url})
  // Tag Manager
  // if(window.dataLayer) {
  //   dataLayer.push({ event: "page_view" });
  // }

};

export default pageView;
